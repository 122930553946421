<template>
    <div class="custom_stylig_for_table">
        <table class="table" v-if="appointmentListData">
            <thead>
                <tr>
                    <th>Patient Name</th>
                    <th class="text-center" width="20%">Date & Time</th>
                    <th class="text-center" width="20%">SYMPTOMS</th>
                    <th class="text-center">Video Message</th>
                    <th class="text-right">Status</th>
                </tr>
                <tr class="empty_cell">
                    <th colspan="5"></th>
                </tr>
            </thead>
            <tbody v-if="appointmentListResults.length > 0">
                <tr v-for="item in appointmentListResults" :key="item.id">
                    <td>
                        <router-link class="consult_patient_name"
                            :to="{ name: 'consultWorkspace', params: { consultID: item.id } }">{{ item.full_name }}
                        </router-link>
                    </td>
                    <td class="text-center">{{ item.appointment_time | LocalTimeFormat }} <br> {{ item.appointment_time
                        | LocalDateFormat }}
                    </td>
                    <td class="text-center">{{ item.symptoms }}</td>
                    <td class="text-center"><a v-if="item.video_file" :href="item.video_file" target="_blank">Recored
                            Video</a><template v-else>-</template>
                    </td>
                    <td class="text-right">
                        <div class="appointment_status_toggle_action appointemnt_status_closed"
                            v-if="item.is_cancelled">
                            <label class="badge badge-danger">Cancelled</label>
                        </div>
                        <div class="appointment_status_toggle_action appointemnt_status_closed"
                            v-else-if="item.is_closed">
                            <label class="badge badge-danger">Closed</label>
                        </div>
                        <div class="appointment_status_toggle_action appointemnt_status_closed"
                            v-else-if="item.is_approved">
                            <label class="badge badge-success">Confirmed</label>
                            <label class="badge badge-danger cursor_pointer ml-2"
                                @click="appointmentStatusUpdatedMethod(item, 'Close')">Close</label>
                        </div>
                        <div class="appointment_status_toggle_action" v-else>
                            <label class="badge badge-primary cursor_pointer"
                                @click="appointmentStatusUpdatedMethod(item, 'Apporve')">Confirm</label>
                            <label class="badge badge-danger cursor_pointer ml-2"
                                @click="appointmentStatusUpdatedMethod(item, 'Cancel')">Cancel</label>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="5" class="text-center">You don't have any requests appointments</td>
                </tr>
            </tbody>
        </table>
        <div class="compontent-loader" v-else>
            <div class="spinner"></div>
        </div>
        <appointment-status-popup v-if="appointmentStatusPopup" :appointmentStatusForData="appointmentStatusForData"
            :appointmentStatusForFLAG="appointmentStatusForFlag"></appointment-status-popup>
    </div>
</template>


<script>
/*eslint-disable */
import appointmentStatusPopup from '@/components/private/appointments/appointment-status-change-popup/';
import { bus } from '@/main';
export default {
    components: {
        'appointment-status-popup': appointmentStatusPopup,
    },
    props: [],
    data() {
        return {
            appointmentListData: null,
            appointmentListResults: [],
            appointmentStatusPopup: false,
            appointmentStatusForData: null,
            appointmentStatusForFlag: '',
        }
    },
    computed: {
    },
    mounted() {
        this.getRequestAppointmentsMethod();
        bus.$on('appointmentStatusPopupBus', (data) => {
            this.appointmentStatusPopup = data
            if(!data){
                this.getRequestAppointmentsMethod();
            }
        });
    },
    methods: {
        getRequestAppointmentsMethod(){
            this.$store.dispatch('getPatientsListByFilterAction', { filter: 'completed' }).then((res) => {
                this.appointmentListData = res.data
                this.appointmentListResults = this.appointmentListData.data
            })
        },
        appointmentStatusUpdatedMethod(data, flag) {
            this.appointmentStatusPopup = true
            this.appointmentStatusForData = data
            this.appointmentStatusForFlag = flag
        },
    },
}
</script>
<style lang="scss" scoped>
.custom_stylig_for_table {
    .table {
        margin: 0px;
        letter-spacing: 0.5px;
        color: #ffffff;
        border-collapse: separate;
        border-spacing: 0 3px;

        thead {
            th {
                border-bottom: 0;
                font-size: 19px;
                padding: 10px 8px;
                font-family: 'MyriadProRegular', sans-serif;
                text-transform: uppercase;
                border-top: 0;
                transition: all .5s ease;
                -webkit-transition: all .5s ease;
                -moz-transition: all ease .5s;
                background: rgba(228, 233, 237, .1);
                color: #87cefa;
            }

            .empty_cell th {
                padding: 0px !important;
                background: none !important;
            }
        }

        tbody {
            td {
                color: #fff;
                vertical-align: middle;
                padding: 10px 8px;
                font-size: 20px;
                font-family: 'MyriadProRegular', sans-serif;
                transition: all .5s ease;
                -webkit-transition: all .5s ease;
                -moz-transition: all ease .5s;
                height: 70px;
                line-height: 1.3;
                border-top: 0;
                background: rgba(228, 233, 237, .1);

                &.symptoms_item span:last-child i {
                    display: none;
                }

                a {
                    color: #fff;
                    text-decoration: none;

                    &:hover {
                        color: #ffd400;
                    }
                    &.consult_patient_name {
                        display: inline-block;
                        font-size: 16px;
                        text-align: justify;
                        background: rgba(255, 255, 255, 0.1);
                        padding: 8px 15px;
                        border-radius: 5px;
                        color: #fff2b0 !important;
                        line-height: 1.2;
                        &:hover{
                        background: #87cefa !important;
                        color: #22292f !important;
                        }
                    }
                }
            }
        }
    }

    .appointment_status_toggle_action {
        label {
            margin-bottom: 0;
        }

        .badge {
            font-size: 16px;
            font-weight: normal;
            padding: 8px 14px;
            line-height: 1;
            border-radius: 3px;

            &.cursor_pointer {
                cursor: pointer;

                &:hover {
                    background: lightskyblue !important;
                    color: #000000 !important;
                }
            }
        }
    }
}
</style>